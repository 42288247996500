.featherlight .featherlight-content {
    border: 0!important;
    padding: 0!important;
  }

  .featherlight-next {
    background: none!important;
  }

  .featherlight-previous {
    background: none!important;
  }

  p + p {
    margin-top: 1em;
  }
