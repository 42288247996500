.content-row {
  &:not(:last-child) {
    margin-bottom: 2em;
  }
  @include media-breakpoint-up(md) {
    &:nth-child(2n) {
      .col-md-4 {
        order: 2;
      }
      .col-md-8 {
        order: 1;
      }
    }
  }
}
