$blue: #0B71BB;
$teal: #0EB8B8;
$yellow: #fbe156;

$theme-colors: (
  "primary": $blue,
  "secondary": $teal
);

$font-family-sans-serif: 'Raleway', sans-serif;
$font-family-headings: 'IBM Plex Sans', sans-serif;