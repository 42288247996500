.jumbotron {
  color: white;
  border-radius: 0;
  height: 20vh;
  display: flex;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  margin-top: 63px;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 10px solid $yellow;
  @include media-breakpoint-up(md){
    height: 40vh;
  }
  h1 {
    text-shadow: 1px 1px 1px rgba(150, 150, 150, 1);
  }
  &.tour {
    position: relative;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 0;
    padding: 0;
    margin: 103px 0 0 0;
    .content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .btn {
      position: absolute;
      bottom: 1em;
      left: 1em;
    }
  }
}

.view-photos {
  a {
    text-decoration: none;
    bottom: 0;
    left: 0;
    p {
      width: 134px;
      padding: 8px 15px;
      background-color: #eee;
      color: $teal;
      border-radius: 5px;
      margin-left: 30px;
    }
      &:nth-child(n+2) {
          display: none;
    }
  }
}

.header-home {
  height: 100vh;
  position: relative;
  z-index: 0;
  margin-top: 100px;
  overflow: hidden;
  .tour-categories {
    display: none;
    
    @include media-breakpoint-up(lg) {
      display: block;
    }
    z-index: 0;
    height: 100vh;
    position: relative;
    a { color: white; }
    .nav {
      padding: 2em 0 4em;
      overflow-y: hidden;
      z-index: 1; 
      height: 100%;
      justify-content: flex-start;
      flex-wrap: nowrap;
      .nav-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        &:last-child{ margin-bottom: 2em; }
        i { margin-bottom: .5em; }
      }
    }
    .background {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.5);
      z-index: -1;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    padding: 0 2em;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(black, 0.3);
    z-index: -1;
  }
  .bkg-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    video {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
    }
  }
}
