body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  main {
    flex: 1 0 auto;
    margin: 3em 0 5em;
  }
  .tours {
    margin: 1em 0 5em;
  }
  .tour-slider {
    background-color: #f3f3f3;
    border-bottom: $yellow 10px solid;
    border-top: $yellow 10px solid;
  }
}