.home-tabs {
  .nav-pills {
    .nav-link {
      background-color: gray('100');
      border-radius: 0;
      position: relative;
      border: 1px solid gray('300');
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      text-align: center;
      padding: 1em;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0;
        width: 100%;
        background-color: theme-color('primary');
        transition: height 0.1s ease-in-out;
      }
      &.active {
        color: theme-color('primary');
        border-bottom: 1px solid theme-color('primary');
        &:after {
          height: 5px;
        }
      }
    }
  }
  .tab-content {
    padding:2em 1em;
    @include media-breakpoint-up(md) {
      padding: 4em;
    }
  }
}