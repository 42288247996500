.newsletter {
  background: theme-color('primary');
  padding: 4em 0;
  text-align: center;
  color: white;
  .newsletter-form {
    text-align: left;
    .btn-outline-secondary {
      border-color: white;
      color: white;
      &:hover {
        border-color: theme-color('secondary');
      }
    }
  }
}