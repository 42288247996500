footer {
  margin-top: 3em;
  background: white;
  .logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 1em;
    }
  }
  .footer-logos {
    display: flex;
    align-items: center;
    .col-md-3 {
      text-align: center;
      &:not(:last-child) {
        img {
          margin-bottom: 1em;
        }
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .footer-links {
    padding: 2em 0;
    text-align: center;
    h3 { font-size: 1rem; }
    li { margin: 1em 0; }
    @include media-breakpoint-up(md) {
      li { margin: initial; }
      text-align: left;
      margin: initial;
    }
  }
  .footer-bottom {
    font-size: 0.8rem;
    padding: 1em 0;
    color: white;
    background: theme-color('primary');
    a {
      color: white;
    }
    .legal {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: left;
      }
    }
    .copyright {
      text-align: center;
    }
    .social-icons {
      text-align: center;
      @include media-breakpoint-up(md) {
        text-align: right;
      }
      i:first-child{
        margin-right: .2em;
      }
      i:nth-child(2) {
        margin-left: .2em;
      }
    }
  }
}
