.utility-bar {
  height: 40px;
  background-color: $blue;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  .container {
    display: flex;
    justify-content: center;
  }
  .row {
    width: 100%;
    text-align: center;
    .phone-numbers {
      //text-align: left;
      a {
        color: white;
        &:nth-child(1) {
          margin-right: 30px;
        }
      }
    }
    .utility-buttons {
      text-align: right;
      .btn {
        border-radius: 20px;
        padding: .15rem 1.5rem;
        margin: 0 0.2em;
        display: inline-block;
        font-size:0.9em;
      }
    }
  }
}

@media (min-width: 768px) {
  .utility-bar {
    .row {
      .phone-numbers {
        //text-align: center;
      }
    }
  }
}

.navbar {
  background-color: white;
  font-weight: 700;
  margin-top: 40px;
  @include media-breakpoint-up(lg) {
    padding: 0 1rem;
  }
  .navbar-brand {
    position: absolute;
    top: -100%;
    left: 4em;
    width: 100px;
    height: 40px;
    transition: top 0.2s ease-in-out;
    text-indent: -100%;
    background: url(/assets/branding/logo.svg) no-repeat center/contain;
    @include media-breakpoint-up(lg){
      left: 1em;
      width: 180px;
      height: 50px;
    }
    &.scrolled {
      top: 0.3em;
    }
  }
  .navbar-collapse {
    @include media-breakpoint-up(lg) {
      margin-left: 120px;
    }
  }
  .nav-item {
    @include media-breakpoint-down(lg) {
      font-size: 12px;
    }
    position: relative;
    margin: 0 5px;
    padding: .5rem 0;
    &:after {
      content: '';
      position: absolute;
      max-height: 5px;
      width: 100%;
      bottom: 0;
      background-color: theme-color('primary');
    }
    &.active {
      &:after { height: 5px; }
    }
  }
  .phone-number {
    a {color: $teal; }
  }
}

.sticky {
  position: fixed;
  top: 100px;
  width: 100%;
  background-color: white;
  border-top: 10px solid #fbe156;
  z-index: 1030;
  hr {
    margin-bottom: 0;
  }
  .container {
    padding-top: 1em;
  }
}

.tour-body-margin {
  margin-top: 80px;
}