.blog-card-body {
  padding-left: 0px;
  padding-right: 0px;
}

.blog-card-date {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0px;
}

.blog-card-category-text {
  font-size: 14px;
  line-height: 14px;
  margin-bottom: 0px;
}

.blog-card-text {
  font-size: 14px !important;
  line-height: 14px !important;
  margin-bottom: 0px;
}

.blog-article-image {
  object-fit: cover;
  width: 100%;
  height: auto;
  min-height: 300px;
  max-height: 600px;
}

/* Media Queries - 1200px col-lg or less */
@media (max-width: 1200px) {
  .blog-tour-card {
    padding: 20px;
  }
}