.card-link {
  color: inherit;
  .tour-card {
    .card-img-top {
      height: 10em;
    }
    .category-icons {
      margin-bottom: 0.5em;
      i {
        color: theme-color('primary');
      }
    }
    border: none;
  }
}

.spacer-wrap {
  margin-bottom: 1em;
}

#highlights {
  ul {
    list-style: none;
    li:before {
      font-family: 'FriendshipTours';
      content: '\f00c';
      margin:0 5px 0 -25px;
      color: $blue;
    }
  }
}

.gallery-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
  margin: .1em;
}