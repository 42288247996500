.home-about {
  margin-top: -2em;
  margin-bottom: 3em;
  .card {
    @extend .mdc-elevation--z8;
    @include media-breakpoint-up(md) {
      padding: 1em 8em; 
    }
  }
}
.tour-slider {
  .jumbotron {
    border-radius: 0;
  }
}

.card-positioning {
  display: flex!important;
  justify-content: center;
}