.btn {
  border-radius: 30px;
  padding: .375rem 2rem;
}
.btn-secondary {
  @extend .mdc-elevation--z5;
  box-shadow: none;
}

a.btn.btn-secondary.not-available {
  background-color: rgb(194, 194, 194);
  color: black;
  border: none;
  &:hover {
    background-color: rgb(194, 194, 194);
    cursor: normal!important;
  }
  &:focus {
    background-color: rgb(194, 194, 194);
    border: none;
    color: black;
  }
}

.btn-link {
  color: black;
  font-weight: 700;
  padding-left: 0;
  &:after {
    content: '\2192';
    margin-left: 0.5em;
    transition: margin-left 0.1s ease-in-out;
  }
  &:hover, &:focus {
    color: theme-color('secondary');
    text-decoration: none;
    &:after {
      margin-left: 1em;
    }
  }
}