h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-headings;
}

h4 {
  color: theme-color('primary');
}

h5 {
  &.tab-header {
    text-transform: uppercase;
    position: relative;
    padding-top: 10px;
    font-size: 0.8rem;
    color: theme-color('secondary');
    &:before {
      content: '';
      position: absolute;
      height: 2px;
      top: 0;
      background-color: theme-color('secondary');
      width: 50px;
    }
  }
}

h3 {
  i {
    color: theme-color('primary');
    margin-right: 0.5em;
  }
}

p {
  line-height: 1.5;
  margin-bottom: 0;
  i {
    color: theme-color('primary');
    margin-right: 1em;
  }
}

strong {
  font-weight: 700;
  color: theme-color('primary');
}

html {
  font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}
body {
  font-weight: 300;
}

.departure-date {
  font-size: 2rem;
  font-weight: normal;
}

.display-4 {
  font-weight: normal;
}